import React, { useState } from "react"
import {
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  Media,
  Nav,
  Row,
  Tab,
} from "react-bootstrap"
import classNames from "classnames"
import * as style from "./ServiceForm.module.scss"

import CartIcon from "../../images/icon-cart-icon-cart.inline.svg"
import CogIcon from "../../images/icon-services-icon-service.inline.svg"
import { useBicycleServiceContext } from "../../context/BicycleService.context"
import { STEP_LOCATION, STEP_SERVICES, PACKAGE } from "../../utils/bicycleServices.util"
import {BasicServiceList, AnnualServiceList, SafetyCheckList, FullRebuildList} from "./UI/ServiceList"



export const CUSTOM_SERVICES = [
  { id: "service_1", value: 'Puncture repair per wheel', price: 19, isChecked: false},
  { id: "service_2", value: 'Brake service front and rear', price: 24, isChecked: false},
  { id: "service_3", value: 'Gear adjustment', price: 18, isChecked: false},
  { id: "service_4", value: 'Cable fitting per cable', price: 65, isChecked: false},
  { id: "service_5", value: 'Wheel true from', price: 14, isChecked: false},
  { id: "service_6", value: 'Spoke replacement', price: 20, isChecked: false},
  { id: "service_7", value: 'Accessory fitment', price: 35, isChecked: false},
  { id: "service_8", value: 'Battery capacity test', price: 17, isChecked: false},
  { id: "service_9", value: 'Software update', price: 19, isChecked: false},
  { id: "service_10", value: 'Wire replacement', price: 40, isChecked: false}
];


function ServiceForm({changeTab}) {

  const { state: { steps, formModel }, dispatch } = useBicycleServiceContext();

  const [ customTotal, setCustomTotal ] = useState(formModel?.service.name === "custom" ? formModel.service.price : 0);
  const [ customServices, setCustomServices ] = useState(CUSTOM_SERVICES);

  const defaultTab = formModel?.service.name === "custom" ? "second" : "first";


  /*
    Custom Services: on checkbox selection
  */
  const handleChange = e => {

    // amount: e.target.dataset.amount
    // checked: e.target.checked
    // value: e.target.value
    // id: e.target.id

    const target = e.target;

    let updatedCustomServices = customServices;
    updatedCustomServices.forEach(x => {
       if (x.value === target.value)
          x.isChecked = target.checked;
    });
    setCustomServices(updatedCustomServices);

    calculateTotal(target.dataset.amount, target.checked);
  }

  /*
    Custom Services: calculate total
  */
  const calculateTotal = (amount, direction) => {

    const x = parseFloat(customTotal);
    const y = parseFloat(amount);

    const newTotal = direction ? parseFloat(x+y).toFixed(2) : parseFloat(x-y).toFixed(2);
    setCustomTotal(newTotal);
  }

  /*
    Update steps
  */
  const updateSteps = () => {

    let updatedSteps = steps;

    updatedSteps.forEach(x => {
       if (x.id === STEP_SERVICES) {
         x.isComplete = true;
       }
       if (x.id === STEP_LOCATION) {
        x.isDisabled = false;
      }
    });

    dispatch({ type: 'UPDATE_STEPS', updatedSteps });
  }


  /*
    Handle form submission
  */
  const _handleSubmitForCustom = () => {

    // 1 update steps
    updateSteps();

    // 2 update formModel
    dispatch({ type: 'UPDATE_SERVICE', service: { name: PACKAGE.CUSTOM.name, price: customTotal, details: customServices}});

    // go to next step
    changeTab(STEP_LOCATION);
  }


  const _handleSubmitForSafetyCheck = () => {

    // 1 update steps
    updateSteps();

    // 2 update formModel
    dispatch({ type: 'UPDATE_SERVICE', service: { ...PACKAGE.SAFETY_CHECK, details: []}});

    // go to next step
    changeTab(STEP_LOCATION);
  }

  const _handleSubmitForFullRebuild = () => {

    // 1 update steps
    updateSteps();

    // 2 update formModel
    dispatch({ type: 'UPDATE_SERVICE', service: { ...PACKAGE.FULL_REBUILD, details: []}});

    // go to next step
    changeTab(STEP_LOCATION);
  }

  const _handleSubmitForBasic = () => {

    // 1 update steps
    updateSteps();

    // 2 update formModel
    dispatch({ type: 'UPDATE_SERVICE', service: { ...PACKAGE.BASIC, details: []}});

    // go to next step
    changeTab(STEP_LOCATION);
  }

  const _handleSubmitForPremium = () => {

    // 1 update steps
    updateSteps();

    // 2 update formModel
    dispatch({ type: 'UPDATE_SERVICE', service: { ...PACKAGE.ANNUAL, details: []}});

    // next step
    changeTab(STEP_LOCATION);
  }

  return (
    <>
      <h2 className="text-uppercase text-center">
        Select a package or custom your needed services
      </h2>

      <Tab.Container id="packageType" defaultActiveKey={defaultTab}>
        <div className={pillContainerClass}>
          <Nav className={style.pills} variant="pills">
            <Nav.Item>
              <Nav.Link eventKey="first">PACKAGES</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">CUSTOM</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey="first">
            <Row className={"mb-3"}>
              <Col className={"mb-3"} md={6}>
                <Card className="px-3 py-5 p-sm-5 border-0">
                  <div className={packageHeaderClass}>
                    <h3 className="h2 mb-0">BASIC SERVICE</h3>
                    <h3 className="mb-0">
                      <span className="small">{process.env.CURRENCY_SYMBOL}</span>
                      <span className="display-4">{PACKAGE.BASIC.price}</span>
                    </h3>
                  </div>
                  <hr />
                  <BasicServiceList />
                  <Button block variant="primary" onClick={_handleSubmitForBasic}>
                    <CogIcon /> &nbsp;Book
                    Basic Package
                  </Button>
                </Card>
              </Col>
              <br />
              <Col className={"mb-3"} md={6}>
                <Card className="px-3 py-5 p-sm-5 border-0">
                  <div className={packageHeaderClass}>
                    <h3 className="h2 mb-0">ANNUAL SERVICE</h3>
                    <h3 className="mb-0">
                      <span className="small">{process.env.CURRENCY_SYMBOL}</span>
                      <span className="display-4">{PACKAGE.ANNUAL.price}</span>
                    </h3>
                  </div>
                  <hr />
                  <AnnualServiceList />
                  <Button block variant="primary" onClick={_handleSubmitForPremium}>
                    <CogIcon /> &nbsp;Book
                    Premium Package
                  </Button>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className={"mb-3"} md={6}>
                <Card className="px-3 py-5 p-sm-5 border-0">
                  <div className={packageHeaderClass}>
                    <h3 className="h2 mb-0">SAFETY CHECK</h3>
                    <h3 className="mb-0">
                      <span className="small">{process.env.CURRENCY_SYMBOL}</span>
                      <span className="display-4">{PACKAGE.SAFETY_CHECK.price}</span>
                    </h3>
                  </div>
                  <hr />
                  <SafetyCheckList />
                  <Button block variant="primary" onClick={_handleSubmitForSafetyCheck}>
                    <CogIcon /> &nbsp;Book
                    Safety Check
                  </Button>
                </Card>
              </Col>

              <Col className={"mb-3"} md={6}>
                <Card className="px-3 py-5 p-sm-5 border-0">
                  <div className={packageHeaderClass}>
                    <h3 className="h2 mb-0">FULL REBUILD</h3>
                    <h3 className="mb-0">
                      <span className="small">{process.env.CURRENCY_SYMBOL}</span>
                      <span className="display-4">{PACKAGE.FULL_REBUILD.price}</span>
                    </h3>
                  </div>
                  <hr />
                  <FullRebuildList />
                  <Button block variant="primary" onClick={_handleSubmitForFullRebuild}>
                    <CogIcon /> &nbsp;Book
                    Full Rebuild
                  </Button>
                </Card>
              </Col>
            </Row>
          </Tab.Pane>

          <Tab.Pane eventKey="second">
            <Row className="justify-content-center">
              <Col lg={6} md={10}>
                <Card className="px-3 py-5 p-sm-5 border-0">
                  <div className={packageHeaderClass}>
                    <h3 className="h2 mb-0">CUSTOM SERVICES</h3>
                    <h3 className="mb-0">
                      <span className="small">{process.env.CURRENCY_SYMBOL}</span>
                      <span className="display-4">{customTotal}</span>
                    </h3>
                  </div>
                  <hr />

                  <ListGroup className="mb-5" variant="flush">
                    {
                      customServices.map((x, i) => (
                        <ListGroup.Item key={i}>
                          <Media>
                            <Media.Body>
                              <Form.Check
                                type="checkbox"
                                id={x.id}
                                label={x.value}
                                value={x.value}
                                data-amount={x.price}
                                checked={x.isChecked}
                                onChange={(e) => handleChange(e)}
                              />
                            </Media.Body>
                            <span>{process.env.CURRENCY_SYMBOL}{x.price}</span>
                          </Media>
                        </ListGroup.Item>
                      ))
                    }
                    {/* <ListGroup.Item>
                      <Media>
                        <Media.Body>
                          <Form.Check
                            type="checkbox"
                            id="Puncture-repair-per-wheel"
                            label="Puncture repair per wheel"
                            data-amount={19}
                            value="Puncture repair per wheel"
                            onChange={(e) => handleChange(e)}
                          />
                        </Media.Body>
                        <span>{process.env.CURRENCY_SYMBOL}19</span>
                      </Media>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Media>
                        <Media.Body>
                          <Form.Check
                            type="checkbox"
                            id="Brake-service-front-and-rear"
                            label="Brake service front and rear"
                            data-amount={24}
                            value="Brake service front and rear"
                            onChange={(e) => handleChange(e)}
                          />
                        </Media.Body>
                        <span>{process.env.CURRENCY_SYMBOL}24</span>
                      </Media>
                    </ListGroup.Item>
                    <ListGroup.Item className={style.listGroupBorder}>
                      <Media>
                        <Media.Body>
                          <Form.Check
                            type="checkbox"
                            id="Gear-adjustment"
                            label="Gear adjustment"
                            data-amount={18}
                            value="Gear adjustment"
                          />
                        </Media.Body>
                        <span>{process.env.CURRENCY_SYMBOL}18</span>
                      </Media>
                    </ListGroup.Item> */}
                  </ListGroup>

                  <Button block variant="primary"
                      disabled={customTotal <= 0}
                      onClick={_handleSubmitForCustom}>
                    <CartIcon /> &nbsp;Book
                    Custom Services
                  </Button>
                </Card>
              </Col>
            </Row>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  )
}

const pillContainerClass = classNames("d-flex", "justify-content-center", "my-5")
const packageHeaderClass = classNames("d-flex", "justify-content-between", "align-items-center")

export default ServiceForm
