import React, { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap"
import CogIcon from "../../images/icon-services-icon-service.inline.svg"
import { useBicycleServiceContext } from "../../context/BicycleService.context";
import { STEP_CONTACT, STEP_LOCATION, STEP_SERVICES, TermsLabel } from "../../utils/bicycleServices.util";
import isEmail from 'validator/lib/isEmail';
import { ServiceListHandler } from "../../utils/bicycleServices.util";

const InfoForm = React.memo(({ dispatch, formModel }) => {

  const {firstName: firstNameState, lastName: lastNameState, email: emailState, phone: phoneState, acceptTerms} = formModel;

  const [firstName, setFirstName] = useState(firstNameState);
  const [lastName, setLastName] = useState(lastNameState);
  const [email, setEmail] = useState(emailState);
  const [phone, setPhone] = useState(phoneState);

  const [isTermsChecked, setIsTermsChecked] = useState(acceptTerms);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const didMount1 = useRef(true);
  //const didMount2 = useRef(true);

  /**
   *
   * Enable Form Submission checker
   */
  useEffect(() => {
    setIsEnabled(firstName.length > 0 && lastName.length > 0 && isEmail(email) && isTermsChecked)
  }, [firstName, lastName, email, isTermsChecked])


  /**
   *
   * T&Cs Checkbox handler
   */
  useEffect(() => {
    if (didMount1.current) {
      didMount1.current = false;
    } else {

      dispatch({ type: 'UPDATE_INFO', firstName, lastName, email, phone});

      dispatch({ type: 'UPDATE_TERMS_CHECK', isTermsChecked});
    }
  }, [isTermsChecked])


  /**
   *
   * Dispatch form values to Context after delay
   * todo: Removed as its causing a weird ui glitch. Will replace with better solution later.
   */
  return (
    <>
      <Form.Group>
        <Form.Label>First Name*</Form.Label>
        <Form.Control
          type="text" name="firstName" required
          value={firstName}
          onChange={e => setFirstName(e.target.value)} />
        <Form.Control.Feedback type="invalid">
          Please enter your first name
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>Last Name*</Form.Label>
        <Form.Control
          type="text" name="lastName" required
          value={lastName}
          onChange={e => setLastName(e.target.value)} />
        <Form.Control.Feedback type="invalid">
          Please enter your last name
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>Email*</Form.Label>
        <Form.Control
          type="email" placeholder="E.g arianne.whittaker@gmail.com" name="email" required
          value={email} onChange={e => setEmail(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          Please enter your email
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>
          Telephone <span className="text-grey-600">- Optional</span>
        </Form.Label>
        <Form.Control
          type="tel" name="phone"
          value={phone} onChange={e => setPhone(e.target.value)} />
      </Form.Group>

      <Form.Group>
        <Form.Check
          type="checkbox"
          id="terms"
          label={<TermsLabel />}
          value="terms"
          required
          checked={isTermsChecked}
          onChange={(e) => setIsTermsChecked(!isTermsChecked)}
        />
      </Form.Group>

      <div className={confirmationRowTotalClass}>
        <small className="font-weight-bold">
          TOTAL TO PAY AT THE SHOP
        </small>
        <h2 className="text-right"><small>{process.env.CURRENCY_SYMBOL}</small>{formModel.service.price}</h2>
      </div>

      <Button block variant="primary"
        onClick={() => setIsSubmitting(true)}
        type="submit"
        disabled={!isEnabled}>
        {
          isSubmitting ?
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
          :
          <>
            <CogIcon />&nbsp;Confirm My Booking
          </>
        }
      </Button>
    </>
  )
}

);


function ReviewForm({ changeTab }) {

  const { state: { formModel }, dispatch } = useBicycleServiceContext();

  return (
    <>
      <h2 className="text-uppercase text-center">
        Review and confirm your booking
      </h2>

      <Row className="justify-content-center mt-5">

        <Col lg={6}>
          <Card className="border-0">

            <div className="px-3 pt-5 pt-sm-5 px-sm-5">
              <h3 className={h3HeadingClass}>Appointment</h3>

              <h4 className={h4HeadingClass}>DATE OF APPOINTMENT</h4>
              <p className="mb-0">{formModel.appointmentDate}</p>
              <Button className="pl-0 mb-4" variant="link" onClick={() => changeTab(STEP_CONTACT)}>Change</Button>

              <h4 className={h4HeadingClass}>STORE OF APPOINTMENT</h4>
              <p className="mb-0">{formModel.store.name}</p>
              <Button className="pl-0" variant="link" onClick={() => changeTab(STEP_LOCATION)}>
                Change
              </Button>
            </div>
            <hr />

            <div className="px-3 pt-2 pb-3 pb-sm-5 px-sm-5">
              <h3 className={h3HeadingClass}>Services</h3>
              <h4 className={h4HeadingClass}>{formModel.service.name} Services</h4>
              <ServiceListHandler service={formModel.service} serviceDetails={formModel.serviceDetails} />

              <div className={serviceRowTotalClass}>
                <small className="font-weight-bold">TOTAL</small>
                <span className="font-weight-bold text-right">{process.env.CURRENCY_SYMBOL}{formModel.service.price}</span>
              </div>

              <Button className="pl-0" variant="link" onClick={() => changeTab(STEP_SERVICES)}>
                Change
              </Button>
            </div>


          </Card>
        </Col>


        <Col lg={6}>
          <Card className="p-3 pt-5 p-sm-5 border-0">
            <h3 className={h3HeadingClass}>Your Information</h3>
            <InfoForm formModel={formModel} dispatch={dispatch} />
          </Card>
        </Col>
      </Row>
    </>
  )
}

const serviceRowTotalClass = classNames("d-flex", "justify-content-between", "align-items-center", "mt-4")
const confirmationRowTotalClass = classNames("d-flex", "justify-content-between", "align-items-center", "my-4")
const h4HeadingClass = classNames("text-uppercase", "h6", "text-grey-600")
const h3HeadingClass = classNames("text-uppercase", "h2", "mb-4")

export default ReviewForm;
