import React from "react"
import {Col, Container, Row} from "react-bootstrap"
import Layout from "../components/Layout"

import { BicycleServiceProvider } from "../context/BicycleService.context"
import BookingServiceForm from "../components/BookingService/Index"


const BicycleServices = ({location}) => {


  return (
    <Layout
      staticSeo={{
        seoTitle: "Book a service",
        seoDescription: "Book a service",
      }}
      location={{ pathname: "bicycleServices" }}>
      <BicycleServiceProvider>
        <Container fluid="xl">
          <Row className="justify-content-center">
            <Col className="text-center" md={8}>
              <h1 className="text-uppercase">Book a service</h1>
              <p>
                When booking your E-Bike in for servicing or repairs you can be
                confident that you are in good hands. Our technicians are fully
                Cytech qualified and here to help make sure you get the most out
                of your bike.
              </p>
            </Col>
            <Col md={12}>
              <BookingServiceForm location={location} />
            </Col>
            <Col md={12}></Col>
          </Row>
        </Container>
        </BicycleServiceProvider>

    </Layout>
  )
}

export default BicycleServices
