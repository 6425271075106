// extracted by mini-css-extract-plugin
export var bgGrey100 = "ServiceForm-module--bg-grey-100--af764";
export var bgGrey150 = "ServiceForm-module--bg-grey-150--bc9ba";
export var bgGrey200 = "ServiceForm-module--bg-grey-200--66e0a";
export var bgGrey300 = "ServiceForm-module--bg-grey-300--2dd39";
export var bgGrey400 = "ServiceForm-module--bg-grey-400--6f5ab";
export var bgGrey500 = "ServiceForm-module--bg-grey-500--3a3a3";
export var bgGrey600 = "ServiceForm-module--bg-grey-600--83385";
export var bgGrey700 = "ServiceForm-module--bg-grey-700--4d931";
export var bgGrey800 = "ServiceForm-module--bg-grey-800--0cd96";
export var bgGrey900 = "ServiceForm-module--bg-grey-900--b75ac";
export var listGroupBorder = "ServiceForm-module--listGroupBorder--50aba";
export var pills = "ServiceForm-module--pills--a53a5";
export var textGrey100 = "ServiceForm-module--text-grey-100--2fb67";
export var textGrey150 = "ServiceForm-module--text-grey-150--301ee";
export var textGrey200 = "ServiceForm-module--text-grey-200--d70b5";
export var textGrey300 = "ServiceForm-module--text-grey-300--77f14";
export var textGrey400 = "ServiceForm-module--text-grey-400--74c1b";
export var textGrey500 = "ServiceForm-module--text-grey-500--42e78";
export var textGrey600 = "ServiceForm-module--text-grey-600--9f22d";
export var textGrey700 = "ServiceForm-module--text-grey-700--5fd60";
export var textGrey800 = "ServiceForm-module--text-grey-800--710a1";
export var textGrey900 = "ServiceForm-module--text-grey-900--9ea20";