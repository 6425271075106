import React, { createContext, useContext, useReducer } from 'react';
import { STEPS_ARRAY, RESERVE_STEPS_ARRAY } from '../utils/bicycleServices.util';

const initialState = {
    steps: STEPS_ARRAY,
    formModel: {
        service: {
          name: null,
          price: null
        },
        serviceDetails: [],
        store: {
          name: null,
          address: null
        },
        appointmentDate: null,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        acceptTerms: false
    },
    reservationSteps: RESERVE_STEPS_ARRAY
  };


const BicycleServiceContext = createContext();


function bicycleServiceReducer(state, action) {
    switch (action.nodeType) {
        case 'UPDATE_STEPS':
            return {...state, steps: action.updatedSteps};
        case 'UPDATE_SERVICE':
            return {...state,
                formModel: {
                    ...state.formModel,
                    service: {
                        name: action.service.name,
                        price: action.service.price
                      },
                      serviceDetails: action.service.details
                    }
                };
        case 'UPDATE_STORE':
          return {...state,
              formModel: {
                  ...state.formModel,
                  store: action.store
                  }
              };
        case 'UPDATE_APPOINTMENT_DATE':
          return {...state,
              formModel: {
                  ...state.formModel,
                  appointmentDate: action.appointmentDate
                  }
              };

        case 'UPDATE_INFO':
          return {...state,
              formModel: {
                  ...state.formModel,
                  firstName: action.firstName,
                  lastName: action.lastName,
                  email: action.email,
                  phone: action.phone
                  }
              };

        case 'UPDATE_TERMS_CHECK':
          return {...state,
              formModel: {
                  ...state.formModel,
                  acceptTerms: action.isTermsChecked
                  }
              };

      default: {
        throw new Error(`Unhandled action type: ${action.nodeType}`);
      }
    }

  }


function BicycleServiceProvider(props) {

  const [state, dispatch] = useReducer(bicycleServiceReducer, initialState);

  return (
    <BicycleServiceContext.Provider value={{ state, dispatch }}>
      {props.children}
    </BicycleServiceContext.Provider>
  );

}

function useBicycleServiceContext() {

    const context = useContext(BicycleServiceContext);

    if (context === undefined) {
      throw new Error('useBicycleServiceContext must be used within a BicycleServiceProvider');
    }
    return context;
  }


export {BicycleServiceProvider,  useBicycleServiceContext}

