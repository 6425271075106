import React, { useEffect, useRef, useState } from 'react'
import classNames from "classnames"
import { Col, Row } from 'react-bootstrap'
import { useBicycleServiceContext } from '../../context/BicycleService.context';
import { convertDateToSelectedFormat, STEP_CONTACT, STEP_REVIEW, TODAY } from '../../utils/bicycleServices.util';
import { useDeviceDetect } from '../../utils/useDeviceDetect';
import MobileDatepicker from './MobileDatepicker';
import DesktopDatepicker from './DesktopDatepicker';
import {getProviders} from '../../api/bookings';


import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Loader } from '../UI/Loader';
import { Alert } from 'react-bootstrap';
import {setCalendarDates} from "../../utils/easyAppointmentsCalendarHelper";


function AppointmentForm({ changeTab }) {

    const { state: { steps, formModel }, dispatch } = useBicycleServiceContext();
    const { isMobile } = useDeviceDetect()
    const didMount = useRef(true);  

    const [date, setDate] = useState(formModel.appointmentDate === null ? new Date(TODAY) : new Date(formModel.appointmentDate));

    const [exceptionDates, setExceptionDates] = useState({});
    const [exceptionDatesFormatted, setExceptionDatesFormatted] = useState();
    const [selectableDatesArray, setSelectableDatesArray] = useState([]);

    const [workingPlan, setWorkingPlan] = useState({});
    const [exceptionDays, setExceptionDays] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [isResolved, setIsResolved] = useState(false);
    const [isRejected, setIsRejected] = useState(false);

    useEffect(() => {

        getProviders().then(res => {
            res.forEach(provider => {
                if (provider.id === parseInt(process.env.EASY_APPOINTMENTS_MECHANIC_ID)) {
                    setExceptionDates(provider.settings.workingPlanExceptions);
                    setWorkingPlan(provider.settings.workingPlan);
                }
            })
        })
        .catch(() => {
            setIsRejected(true);
            setIsLoading(false);
        })

    }, []);

    /*
        easyappointments workplan and exception dates handler  (from Workplan Exceptions)
    */
    useEffect(() => {

        if (didMount.current) {
            didMount.current = false;

        } else {

            setCalendarDates(exceptionDates, workingPlan, setSelectableDatesArray, setExceptionDatesFormatted, setExceptionDays)

            // status
            setIsLoading(false);
            setIsResolved(true);
        }

    }, [exceptionDates, workingPlan]);


    /*
        set selected date on page load if no date is saved in Context
    */
    useEffect(() => {

        if ( selectableDatesArray.length > 0 && formModel.appointmentDate === null) {
            setDate( new Date(selectableDatesArray[0]) );
        }
    }, [selectableDatesArray])



    /*
        Update steps
    */
    const updateSteps = () => {

        let updatedSteps = steps;

        updatedSteps.forEach(x => {
            if (x.id === STEP_CONTACT) {
                x.isComplete = true;
            }
            if (x.id === STEP_REVIEW) {
                x.isDisabled = false;
            }
        });

        dispatch({ type: 'UPDATE_STEPS', updatedSteps });
    }
    /*
        Handle form submission
    */
    const _handleSubmit = () => {

        // 1 update steps
        updateSteps();

        // 2 update formModel
        dispatch({ type: 'UPDATE_APPOINTMENT_DATE', appointmentDate: convertDateToSelectedFormat(date) });

        // go to next step
        changeTab(STEP_REVIEW);
    }

    return (
        <>
            <h2 className="text-uppercase text-center">
                Schedule your appointment
            </h2>
            <p className="text-center">Please select a day during the week you would like to drop your bike off.</p>

            {
                isLoading && <Loader center={true} />
            }

            {
                isRejected && <Alert variant="danger">Apologies but there has been an error. Please refresh the page and try again.</Alert>
            }

            {
                isResolved && isMobile &&
                    <MobileDatepicker
                        submit={_handleSubmit}
                        dateState={[date, setDate]}
                        exceptionDates={exceptionDatesFormatted}
                        selectableDatesArray={selectableDatesArray}
                    />
            }

            {
                isResolved && !isMobile &&
                    <Row className="justify-content-center mt-5">
                        <Col lg={8}>
                            <div className="d-flex justify-content-center">
                                <DesktopDatepicker
                                    submit={_handleSubmit}
                                    dateState={[date, setDate]}
                                    exceptionDates={exceptionDatesFormatted}
                                    minDate={selectableDatesArray[0]}
                                    exceptionDays={exceptionDays}
                                />
                            </div>
                            <p className={disclaimerClass}>
                                We aim to turn bikes around on the same day. Please drop your bike before 11am to help with this. Any bikes after 11am we cannot guarantee will be completed the same day.
                            </p>
                        </Col>
                    </Row>
            }






        </>
    )
}

const disclaimerClass = classNames("text-grey-600", "text-center", "mt-4")

export default AppointmentForm
