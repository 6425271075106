import React, { useState } from "react"
import {
    Form,
    Tab,
    Tabs,
} from "react-bootstrap"
import classNames from "classnames"
import ServiceForm from "./ServiceForm"
import LocationForm from "./LocationForm"
import AppointmentForm from "./AppointmentForm"
import ReviewForm from "./ReviewForm"
import { useBicycleServiceContext } from "../../context/BicycleService.context"
import { TabTitleWithCheckedIcon, STEP_CONTACT, STEP_LOCATION, STEP_REVIEW, STEP_SERVICES, TabTitle } from "../../utils/bicycleServices.util"
import { navigate } from "gatsby"
import * as style from './Index.module.scss'
import { bookAppointment } from "../../api/bookings"



function BookingServiceForm() {

    const [activeStep, setActiveStep] = useState(STEP_SERVICES);
    const { state: { steps, formModel } } = useBicycleServiceContext();

    const handleActiveStep = key => setActiveStep(key);

    /**
     *
     * Step Handler
     */
    const StepContentHandler = ({ content }) => {
        switch (content) {
            case STEP_SERVICES:
                return <ServiceForm changeTab={handleActiveStep} />;
            case STEP_LOCATION:
                return <LocationForm changeTab={handleActiveStep} />;
            case STEP_CONTACT:
                return <AppointmentForm changeTab={handleActiveStep} />;
            case STEP_REVIEW:
                return <ReviewForm changeTab={handleActiveStep} />;

            default:
                return null;
        }
    }

    /**
     *
     * Form Submission
     */
    const handleSubmit = (e) => {

      e.preventDefault();
      const form = e.currentTarget;

      if (form.checkValidity() === true) {

        // Build object to be sent to Easy!Appointments service
        //
        const startTime = new Date(formModel.appointmentDate);
          startTime.setHours(8);
        const endTime = new Date(formModel.appointmentDate);
          endTime.setHours(9);

        const customServicesText = formModel.serviceDetails.length > 0 ?
          formModel.serviceDetails.filter(x => x.isChecked).map(y => y.value).toString()
          :
          '';

        const notes = formModel.serviceDetails.length > 0 ?
          `Custom Service Requested List: ${customServicesText}`
          :
          '';

        const data = {
          appointment: {
            start: startTime,
            end: endTime,
            notes: notes,
            location: formModel.store.name,
            serviceId: 1
          },
          customer: {
            firstName: form.firstName.value,
            lastName: form.lastName.value,
            email: form.email.value,
            phone: form.phone.value,
            notes: ""
          }
        }

        // send data to Easy!Appointments service
        bookAppointment(data)
          .then(res => {
            // redirect to success page
            navigate('/bicycleServicesSuccess', {state: { 'formModel': formModel }})
          })
          .catch(error => {
              console.error('Error:', error);
            });

      } else {
        e.stopPropagation();

      }
    };

    return (
            <Form onSubmit={handleSubmit}>
                <Tabs
                    mountOnEnter={true}
                    className={`${style.tab} mt-4`}
                    id="controlled-tab-example"
                    defaultActiveKey="services"
                    activeKey={activeStep}
                    onSelect={k => setActiveStep(k)}>

                    {steps.map((item, i) => {

                        let title = item.isComplete ?
                          <TabTitleWithCheckedIcon item={item} activeStep={activeStep} />
                          :
                          <TabTitle item={item} activeStep={activeStep} />

                        return (
                            <Tab key={i} eventKey={item.id} title={title} disabled={item.isDisabled}>
                                <div className={tabContainerClass}>
                                    <StepContentHandler content={item.id} />
                                </div>
                            </Tab>
                        )
                    })}

                    {/* <Tab eventKey="services" title={<CheckedIcon title="1 - SERVICES" />}>
                    <div className={tabContainerClass}>
                      <ServiceForm />
                    </div>
                  </Tab>

                  <Tab eventKey="location" title="2 - LOCATION">
                    <div className={tabContainerClass}>
                      <LocationForm changeTab={handleActiveStep} />
                    </div>
                  </Tab>

                  <Tab eventKey="contact" title="3 - APPOINTMENT">
                    <div className={tabContainerClass}>
                      <AppointmentForm />
                    </div>
                  </Tab>

                  <Tab eventKey="review" title="4 - REVIEW">
                    <div className={tabContainerClass}>
                      <ReviewForm />
                    </div>
                  </Tab> */}

                </Tabs>
            </Form>

    )
}

const tabContainerClass = classNames("py-5", "px-3", "bg-grey-150")


export default BookingServiceForm;
